// Variable overrides
thead {
  background: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
  color: #fff;
}

.pagination .page-item .page-link {
  color: #193148;
}

.pagination .page-item.active .page-link {
  background: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
  border-color: #193148;
}

.card .card-header {
  background: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
  color: #fff;
  border-color: rgba(0, 0, 0, 0.125); //darken($value, 12.5%);
}

.c-sidebar-nav {
  background-color: #193148;
  .c-sidebar-nav-dropdown {
    .c-sidebar-nav-link {
      &:hover {
        background-color: #0066b2;
      }
    }
  }
}

.c-sidebar-nav {
  .c-sidebar-nav-item {
    .c-sidebar-nav-link {
      &:hover {
        background-color: #0066b2;
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    &:active,
    &:focus {
      background: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
    }
  }
}

.c-main {
  background-color: #ebedef;
}

.c-sidebar .c-sidebar-minimizer {
  background-color: #1b3955 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #768192;
  background-color: #ebedef;
  border-color: #c4c9d0 !important;
}
