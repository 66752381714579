.c-sidebar .c-sidebar-brand {
  color: white;
  background: white;
}

.text-value {
  font-size: large;
}

.font-size-large {
  font-size: large;
}

.spinner {
  width: "4rem";
  height: "4rem";
}

.spinner--spacer-bottom {
  margin-bottom: 10pt;
}

.c-body {
  background-color: white;
}

.btn-info,
.bg-info,
.badge-info {
  background-color: #134777;
  border-color: #134777;
  background-image: linear-gradient(
    45deg,
    hsl(215deg 64% 17%) 0%,
    hsl(214deg 63% 18%) 11%,
    hsl(215deg 62% 19%) 22%,
    hsl(214deg 62% 19%) 33%,
    hsl(214deg 61% 20%) 44%,
    hsl(213deg 60% 21%) 56%,
    hsl(213deg 59% 21%) 67%,
    hsl(214deg 57% 22%) 78%,
    hsl(213deg 57% 23%) 89%,
    hsl(213deg 56% 23%) 100%
  );
}

.btn-info:hover {
  color: #9bbcdb;
}

.btn-success,
btn-success.disabled,
.btn-success:disabled {
  background-image: linear-gradient(
    45deg,
    hsl(133deg 64% 17%) 0%,
    hsl(131deg 65% 20%) 11%,
    hsl(131deg 66% 23%) 22%,
    hsl(130deg 68% 26%) 33%,
    hsl(129deg 70% 29%) 44%,
    hsl(128deg 70% 32%) 56%,
    hsl(128deg 73% 34%) 67%,
    hsl(126deg 74% 37%) 78%,
    hsl(125deg 75% 40%) 89%,
    hsl(125deg 76% 43%) 100%
  );
}

.text-info {
  color: #0066b2 !important;
}

.bg-danger,
.btn-danger {
  background: linear-gradient(135deg, #ff6d4d, #ca171f) !important;
}

.bg-danger:hover,
.btn-danger:hover {
  background: #ca171f;
  transition: background-color 0.3s ease;
}

.btn-warning,
.bg-warning {
  color: #193148;
  background: linear-gradient(135deg, #e0c70e, #f4c61b) !important;
}
